<template>
    <!-- Error page-->
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <logo />
            <h2 class="brand-text text-primary ml-1">Walleat</h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">
                    {{
                        $route.query.status == "success"
                            ? "Your payment was accepted"
                            : "Your transaction was cancelled"
                    }}
                </h2>
                <p class="mb-2">
                    {{
                        $route.query.status == "success"
                            ? "Back to login again to apply changes."
                            : "You need login again."
                    }}
                </p>

                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    @click="goToHome"
                >
                    Back to Home
                </b-button>

                <!-- image -->
                <b-img fluid :src="imgUrl" alt="Error page" />
            </div>
        </div>
    </div>
    <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
export default {
    components: {
        Logo,
        BLink,
        BButton,
        BImg,
    },
    data() {
        return {
            title: "",
            downImg: require("@/assets/images/pages/error.svg"),
        };
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                this.downImg = require("@/assets/images/pages/error-dark.svg");
                return this.downImg;
            }
            return this.downImg;
        },
    },
    methods: {
        goToHome() {
            window.location.href = `/establecimiento/${this.$route.query.establishment}`;
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
